import React from "react";

function GalleryImage({ image }) {
  return (
    <div className="relative inline-block group">
      <img src={image.src} loading="lazy" alt="" className="block w-full h-auto"/>
      <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-50 flex justify-center items-center transition duration-300">
        <p className="text-white text-center items-center text-lg font-bold opacity-0 group-hover:opacity-100 transition duration-300">
          {image.text}
        </p>
      </div>
    </div>
  );
}

export default GalleryImage;
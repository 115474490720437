import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import NavBar from './NavBar';
import LandingPage from './LandingPage';
import About from './About';
import Reel from './Reel';
import ResumeAndHeadshots from './ResumeAndHeadshots';
import GalleryCarousel from './GalleryCarousel';
import Contact from './Contact';

function MainPage({ homeRef, aboutRef, reelRef, resumeRef, galleryRef, contactRef}) {
  const [isLoaded, setIsLoaded] = useState(false)

  return (
    <div className='flex flex-col'>
      <LandingPage homeRef={homeRef} setIsLoaded={setIsLoaded} />
      <About aboutRef={aboutRef} />
      <ResumeAndHeadshots resumeRef={resumeRef} />
      <GalleryCarousel galleryRef={galleryRef} />
      <Reel reelRef={reelRef} />
      <Contact contactRef={contactRef}/>
    </div>
  );
}

export default MainPage;
import picture2 from './content/HM4A3000.jpg'
import { useEffect, useRef, useState } from 'react';

function LandingPage({ homeRef, setIsLoaded }) {
  const [scrollY, setScrollY] = useState(0);
  const [imageHeight, setImageHeight] = useState(0)
  const imageRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    const updateImageHeight = () => {
      setImageHeight(imageRef.current.clientHeight)
    }

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', updateImageHeight);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleImageLoad = () => {
      if (imageRef.current) {
        setImageHeight(imageRef.current.clientHeight);
      }
    };

    if (imageRef.current) {
      if (imageRef.current.complete) {
        handleImageLoad();
      } else {
        imageRef.current.addEventListener('load', handleImageLoad);
      }
    }

    return () => {
      if (imageRef.current) {
        imageRef.current.removeEventListener('load', handleImageLoad);
      }
    };
  }, []);

  const handleImageLoad = () => {
    setIsLoaded(true)
  }


  return (

    <div
      className="w-screen max-h-screen bg-gray-600 relative flex justify-center items-center"
      ref={homeRef}
      style={{ height: `${imageHeight - 15}px` }}
    >
      <div className={window.innerWidth < 640 ? "text-3xl right-[28vw] relative text-white z-10 tracking-tighter" : "text-7xl sm:text-6xl md:text-9xl lg:text-7xl xl:text-[7rem] text text-white z-10 sm:mt-4 md:mt-32 lg:mt-32 xl:mt-32 relative right-[20vw] bottom-[20vh] tracking-tighter"}>
        <div>Isa</div>
        <div>Providence</div>
      </div>
      <div className={window.innerWidth < 640 ? "bg-black mt-14 justify-center flex items-center fixed top-0 w-full" : "bg-black text-4xl sm:text-6xl md:text-8xl lg:text-9xl xl:text-9xl justify-center flex items-center fixed top-0 w-full"}>
        <img
          ref={imageRef}
          src={picture2}
          alt="Picture of Isa"
          className="w-full h-auto"
          onLoad={handleImageLoad}
          style={{
            transform: `translateY(-${scrollY / 5}px)`,
          }}
        />
      </div>
    </div>
  );
}

export default LandingPage;
import React, { useState } from "react"
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";
import Chicago from './content/_DSC5271.png'
import Bar from './content/DSCN5175.png'
import Ghost from './content/IMG_3130.JPG'
import Wardrobe from './content/ORIENT EXPRESS71342.jpg'
import Museum from './content/IMG_8043.JPG'
import GalleryButton from "./GalleryButton";

function GalleryCarousel({ galleryRef }) {

    const slides = [Wardrobe, Chicago, Bar, Ghost, Museum];

    const [currentIndex, setCurrentIndex] = useState(0);

    const prevSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const nextSlide = () => {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
    };

    return (
        <div ref={galleryRef} className=' w-screen sm:h-[25vh] md:h-[110vh] lg:h-[110vh] m-auto py-8 px-4 sm:py-16 sm:px-8 md:py-16 md:px-12 lg:py-16 lg:px-16 relative bg-background flex flex-col items-center pt-16'>
            <div className="w-full h-64 sm:h-2/3 sm:w-2/3 md:h-2/3 md:w-2/3 lg:h-2/3 lg:w-2/3 group relative bg-black rounded-2xl overflow-hidden mt-16">
                {slides.map((slide, slideIndex) => (
                    <img
                        key={slideIndex}
                        src={slide}
                        alt="Slide"
                        className={`w-full h-full rounded-2xl object-contain duration-500 ${slideIndex === currentIndex ? 'opacity-100' : 'opacity-0'
                            } absolute`}
                    />
                ))}
                <div className='sm:hidden absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 hover:bg-white/50 text-white cursor-pointer transition duration-300'>
                    <BsChevronCompactLeft onClick={prevSlide} size={30} />
                </div>
                <div className='sm:hidden absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 hover:bg-white/50 transition duration-300 text-white cursor-pointer'>
                    <BsChevronCompactRight onClick={nextSlide} size={30} />
                </div>
                <div className='hidden sm:group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 hover:bg-white/50 text-white cursor-pointer transition duration-300'>
                    <BsChevronCompactLeft onClick={prevSlide} size={30} />
                </div>
                <div className='hidden sm:group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 hover:bg-white/50 transition duration-300 text-white cursor-pointer'>
                    <BsChevronCompactRight onClick={nextSlide} size={30} />
                </div>
            </div>
            <div className='flex mt-4 sm:mt-8 lg:mt-10 justify-center py-2'>
                {slides.map((slide, slideIndex) => (
                    <div
                        key={slideIndex}
                        onClick={() => goToSlide(slideIndex)}
                        className='text-2xl cursor-pointer'
                    >
                        <RxDotFilled />
                    </div>
                ))}
            </div>
            <div className="mt-4 sm:mt-8 lg:mt-10">
                <GalleryButton />
            </div>
        </div>
    );
}

export default GalleryCarousel
import React, { useState, useEffect } from "react";
import StackGrid from 'react-stack-grid'
import GalleryImage from "./GalleryImage";
import aa from './content/_DSC5271.png'
import b from './content/_DSC5498.png'
import c from './content/5DB16C71-4CF1-4141-A889-BA56223C4929.png'
import d from './content/C2AF8207-5554-4653-83BE-5326A760ECBD.jpg'
import e from './content/DSCN5175.png'
import f from './content/E7930B3F-1389-4A95-BD11-0167F9799428.png'
import g from './content/HM4A3000.jpg'
import h from './content/HM4A3115.jpg'
import i from './content/HM4A2696.jpg'
import j from './content/HM4A2833.jpg'
import k from './content/IMG_1472.jpeg'
import l from './content/ORIENT EXPRESS70762.jpg'
import m from './content/IMG_3130.JPG'
import n from './content/IMG_3133.JPG'
import o from './content/IMG_3136.JPG'
import p from './content/ORIENT EXPRESS71074.jpg'
import q from './content/IMG_4592.jpeg'
import r from './content/ORIENT EXPRESS71342.jpg'
import s from './content/IMG_6937.JPG'
import t from './content/IMG_7086.jpeg'
import u from './content/IMG_8043.JPG'
import v from './content/IMG_8052.png'
import w from './content/IMG_8257.jpeg'
import x from './content/IMG_8326.JPG'
import y from './content/IMG_8327.JPG'
import z from './content/HM4A3278.jpg'
import a from './content/image0.jpeg'
import ab from './content/ORIENT EXPRESS71404.jpg'


function Gallery() {
    const images = [{ src: a, text: "Ensemble/U.S Emily in 'Our Town' at Syracuse Stage" }, { src: b, text: "Mama Morton in 'Chicago'" }, { src: c, text: "Wednesday in 'The Addams Family'" }, { src: d, text: "Marie Farnsworth in 'Ghost Ship'" }, { src: e, text: "Bartender in 'Wildlife'" }, { src: f, text: "Wednesday in 'The Addams Family'" }, { src: g, text: "" }, { src: h, text: "" }, { src: i, text: "" }, { src: j, text: "" }, { src: k, text: "Ensemble / U.S. Emily in 'Our Town' at Syracuse Stage" }, { src: l, text: "Mary Debenham in 'Murder On The Orient Express' at Syracuse Stage" }, { src: m, text: "Marie Farnsworth in 'Ghost Ship'" }, { src: n, text: "Marie Farnsworth in 'Ghost Ship'" }, { src: o, text: "Marie Farnsworth in 'Ghost Ship'" }, { src: p, text: "Mary Debenham in 'Murder On The Orient Express' at Syracuse Stage" }, { src: q, text: "Marie Farnsworth in 'Ghost Ship'" }, { src: r, text: "Mary Debenham in 'Murder On The Orient Express' at Syracuse Stage" }, { src: s, text: "Nell in 'The Wardrobe'" }, { src: t, text: "" }, { src: u, text: "The Guard in 'Museum'" }, { src: v, text: "The Guard in 'Museum'" }, { src: w, text: "Shakespeare's Globe" }, { src: x, text: "Tuva in 'This Changes Everything'" }, { src: y, text: "Tuva in 'This Changes Everything'" }, { src: z, text: "" }, {src:aa, text: "Mama Morton in 'Chicago'"}, { src: ab, text: "Mary Debenham in 'Murder On The Orient Express' at Syracuse Stage" }]
    const [gridHeight, setGridHeight] = useState(0);
    const [visibleImages, setVisibleImages] = useState(7)

    const handleLayout = (height) => {
        setGridHeight(height);
    };

    const handleLoadMore = () => {
        console.log("hey")
        setVisibleImages((visibleImages) => visibleImages + 7 > images.length ? images.length : visibleImages + 7)
    }

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setGridHeight(Math.min(window.innerHeight, gridHeight));
        }, 30);

        return () => clearTimeout(timeoutId);
    }, [gridHeight]);

    return (
        <div className="mt-16 min-h-screen bg-background">
            <StackGrid
                columnWidth={window.innerWidth < 640 ? (window.innerWidth * 30) / 100 : (window.innerWidth * 32) / 100}
                gutterWidth={10}
                gutterHeight={10}
                style={{ height: gridHeight + "px", maxHeight: "100%" }}
                onLayout={(height) => handleLayout(height)}
                className="bg-background"
            >
                {images.slice(0, visibleImages).map((image, index) => (
                    <GalleryImage key={index} image={image} />
                ))}
            </StackGrid>
            <div className="w-screen flex items-center justify-center pb-10">
                {visibleImages < images.length && (
                    <button className="mt-4 p-2 w-3/4 h-[10vh] bg-button text-white rounded" onClick={handleLoadMore}>
                        Load More
                    </button>
                )}
            </div>
        </div>
    );
}

export default Gallery;
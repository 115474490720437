import { useNavigate, Routes, Route } from 'react-router-dom';
import React from 'react';
import MainPage from './MainPage';
import { useState, useRef } from 'react';
import NavBar from './NavBar';
import './App.css';
import Gallery from './Gallery';

function App() {
  const reelRef = useRef(null);
  const aboutRef = useRef(null);
  const homeRef = useRef(null);
  const resumeRef = useRef(null);
  const galleryRef = useRef(null);
  const contactRef = useRef(null);

  const navigate = useNavigate()

  const scrollToSection = async (ref) => {
    navigate("/")
    
    await new Promise((resolve) => setTimeout(resolve, 20))
    ref && ref.current.scrollIntoView({behavior : "smooth"})
  }

  return (
    <div className='flex flex-col overflow-x-hidden font-body'>
      <NavBar scrollToSection={scrollToSection} homeRef={homeRef} aboutRef={aboutRef} resumeRef={resumeRef} galleryRef={galleryRef} reelRef={reelRef} contactRef={contactRef} />
      <Routes>
        <Route exact path='/' element={<MainPage homeRef={homeRef} galleryRef={galleryRef} contactRef={contactRef} resumeRef={resumeRef} aboutRef={aboutRef} reelRef={reelRef} />} />
        <Route path='/Gallery' element={<Gallery/>} />
      </Routes>
    </div>
  );
}

export default App;

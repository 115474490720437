import React, { useState, useRef } from "react";
import { motion } from "framer-motion";
import emailjs from '@emailjs/browser'

function Contact({ contactRef }) {

    const formRef = useRef();
    const [form, setForm] = useState({
        name: "",
        email: "",
        message: "",
    });

    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { target } = e;
        const { name, value } = target;

        setForm({
            ...form,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);


        emailjs
            .send(
                "service_yfvwqmd",
                "template_flfn32j",
                {
                    from_name: form.name,
                    to_name: "Isa Providence",
                    from_email: form.email,
                    to_email: "isaprovidenceactor@gmail.com",
                    message: form.message,
                },
                "rM_xGGgOmOTd-XH3x"
            )
            .then(
                () => {
                    setLoading(false);
                    alert("Thank you. I will get back to you as soon as possible.");

                    setForm({
                        name: "",
                        email: "",
                        message: "",
                    });
                },
                (error) => {
                    setLoading(false);
                    console.error(error);

                    alert("Ahh, something went wrong. Please try again.");
                }
            );
    };
    return (
        <>
            {window.innerWidth < 640 ?
                <div className='flex flex-col items-center text-black bg-background w-screen z-10 h-full gap-10 pb-10' ref={contactRef}>
                    <motion.div className="w-full flex justify-center items-center"
                        initial={{ x: -200 }}
                        whileInView={{ x: 0 }}
                        transition={{ duration: 0.5, type: "spring", bounce: 0.2, delay: 0.05 }}
                    >
                        <div className="flex flex-col w-full h-full justify-center items-center">
                            <div className={`h-5/6 w-3/4 text-text bg-box border-xl ${window.innerWidth < 640 ? "text-3xl h-full w-3/4" : "text-7xl gap-10 h-5/6 w-3/4"} flex-col text-center shadow-lg shadow-s text-text rounded-lg flex items-center justify-center`}>Get in touch!</div>
                        </div>
                    </motion.div>
                    <motion.div className="w-full h-full flex justify-center items-center"
                        initial={{ x: 200 }}
                        whileInView={{ x: 0 }}
                        transition={{ duration: 0.5, type: "spring", bounce: 0.2, delay: 0.05 }}
                    >
                        <form className='flex flex-col w-full justify-center items-center'>
                            <label className='mb-4 w-3/4 gap-5 flex flex-col'>
                                <span className='text-text font-medium text-xl w-fit rounded-md bg-box pl-3 pr-3'>Your Name</span>
                                <input
                                    type='text'
                                    name='name'
                                    value={form.name}
                                    onChange={handleChange}
                                    placeholder="What's your name?"
                                    className='w-full px-4 py-2 rounded-lg outline-none border border-gray-300'
                                />
                            </label>
                            <label className='mb-4 w-3/4 gap-5 flex flex-col'>
                                <span className='text-text font-medium text-xl w-fit rounded-md bg-box pl-3 pr-3'>Your Email</span>
                                <input
                                    type='email'
                                    name='email'
                                    value={form.email}
                                    onChange={handleChange}
                                    placeholder="What's your email?"
                                    className='w-full px-4 py-2 rounded-lg outline-none border border-gray-300'
                                />
                            </label>
                            <label className='mb-4 w-3/4 gap-5 flex flex-col'>
                                <span className='text-text font-medium text-xl w-fit rounded-md pl-3 pr-3 bg-box'>Your Message</span>
                                <textarea
                                    rows={7}
                                    name='message'
                                    value={form.message}
                                    onChange={handleChange}
                                    placeholder='What do you want to say?'
                                    className='w-full px-4 py-2 rounded-lg outline-none border border-gray-300'
                                />
                            </label>
                            <button
                                type='submit'
                                className='w-3/4 px-4 py-2 rounded-xl outline-none bg-button text-white font-bold shadow-md'
                                onClick={handleSubmit}
                            >
                                {loading ? 'Sending...' : 'Send'}
                            </button>
                        </form>
                    </motion.div>
                </div>
                :
                <div className='grid items-center text-black bg-background w-screen z-10 h-[100vh] grid-cols-2' ref={contactRef}>
                    <motion.div className="w-full h-full flex justify-center items-center"
                        initial={{ x: -200 }}
                        whileInView={{ x: 0 }}
                        transition={{ duration: 0.5, type: "spring", bounce: 0.2, delay: 0.05 }}
                    >
                        <form className='flex flex-col w-full start-col-1 justify-center items-center'>
                            <label className='mb-4 w-3/4 gap-5 flex flex-col'>
                                <span className='text-text font-medium text-xl w-fit rounded-md bg-box pl-3 pr-3'>Your Name</span>
                                <input
                                    type='text'
                                    name='name'
                                    value={form.name}
                                    onChange={handleChange}
                                    placeholder="What's your name?"
                                    className='w-full px-4 py-2 rounded-lg outline-none border border-gray-300'
                                />
                            </label>
                            <label className='mb-4 w-3/4 gap-5 flex flex-col'>
                                <span className='text-text font-medium text-xl w-fit rounded-md bg-box pl-3 pr-3'>Your Email</span>
                                <input
                                    type='email'
                                    name='email'
                                    value={form.email}
                                    onChange={handleChange}
                                    placeholder="What's your email?"
                                    className='w-full px-4 py-2 rounded-lg outline-none border border-gray-300'
                                />
                            </label>
                            <label className='mb-4 w-3/4 gap-5 flex flex-col'>
                                <span className='text-text font-medium text-xl w-fit rounded-md pl-3 pr-3 bg-box'>Your Message</span>
                                <textarea
                                    rows={7}
                                    name='message'
                                    value={form.message}
                                    onChange={handleChange}
                                    placeholder='What do you want to say?'
                                    className='w-full px-4 py-2 rounded-lg outline-none border border-gray-300'
                                />
                            </label>
                            <button
                                type='submit'
                                className='w-3/4 px-4 py-2 rounded-xl outline-none bg-button text-white font-bold shadow-md'
                                onClick={handleSubmit}
                            >
                                {loading ? 'Sending...' : 'Send'}
                            </button>
                        </form>
                    </motion.div>
                    <motion.div className="w-full h-1/2 flex justify-center items-center"
                        initial={{ x: 200 }}
                        whileInView={{ x: 0 }}
                        transition={{ duration: 0.5, type: "spring", bounce: 0.2, delay: 0.05 }}
                    >
                        <div className="flex flex-col w-full h-full justify-center items-center">
                            <div className={`h-5/6 w-3/4 text-text bg-box border-xl ${window.innerWidth < 640 ? "text-3xl h-full w-3/4" : "text-7xl gap-10 h-5/6 w-3/4"} flex-col text-center shadow-lg shadow-s text-text rounded-lg flex items-center justify-center`}>Get in touch!</div>
                        </div>
                    </motion.div>
                </div>
            }
        </>
    )
}

export default Contact
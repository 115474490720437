import React from "react";
import { useNavigate } from "react-router-dom";

function GalleryButton() {

    const navigate = useNavigate()

    function handleClick() {
        navigate("/Gallery")
        console.log("hi")
    }
    return (
        <div className="h-[10vh] w-screen bg-background z-10 justify-center flex">
            <button
                onClick={() => handleClick()}
                className="border-4 h-full rounded-xl bg-button w-full sm:w-1/2 text-3xl sm:text-5xl md:text-3xl lg:text-3xl text-white whitespace-normal px-6 py-2"
            >
                View Full Gallery
            </button>
        </div>
    )
}

export default GalleryButton;
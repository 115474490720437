import React, { useState } from "react";
import HeadShot1 from './content/HM4A2623.jpg'
import HeadShot2 from './content/HM4A2796.jpg'
import HeadShot3 from './content/HM4A2971.jpg'
import HeadShot4 from './content/HM4A3613.jpg'
import file from './content/ISA_PROVIDENCE_H&R.zip'
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";
import ResumePNG from './content/Resume.png'

function ResumeAndHeadshots({ resumeRef }) {

    const slides = [HeadShot2,HeadShot3,HeadShot4,HeadShot1]
    const [currentIndex, setCurrentIndex] = useState(0);

    const prevSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const nextSlide = () => {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
    };

    const handleDownload = async () => {
        try {
            const response = await fetch(file);
            const blob = await response.blob();

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'ISA_PROVIDENCE_H&R.zip';

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    return (
        <div className={`${window.innerWidth < 640 ? 'h-[150vh] flex flex-col' : 'h-[110vh] grid grid-cols-2'} w-screen bg-background z-10  pt-16 pb-16`} ref={resumeRef}>
            <div className='sm:h-1/2 sm:w-full md:h-full md:w-3/4 lg:h-full lg:w-3/4 md:m-auto lg:m-auto md:py-16 lg:py-16 px-4 relative bg-background flex justify-center items-center flex-col col-start-1'>
                <div className="sm:h-full sm:w-5/6 md:h-full md:w-full lg:h-full lg:w-full group relative bg-black rounded-2xl">
                    <img
                        src={ResumePNG}
                        alt="Slide"
                        className='w-full h-full rounded-2xl object-fill duration-500' />
                </div>
                <div className='flex top-4 justify-center py-2'>
                    <div className='text-2xl'>
                        <RxDotFilled className="opacity-0" />
                    </div>
                </div>
            </div>
            <div className='h-full sm:w-full md:w-3/4 lg:w-3/4 md:m-auto lg:m-auto md:py-16 lg:py-16 px-4 relative bg-background flex justify-center items-center flex-col col-start-2'>
                <div className="h-full w-full sm:w-11/12 md:w-5/6 lg:w-5/6 group relative bg-black rounded-2xl">
                    {slides.map((slide, slideIndex) => (
                        <img
                            key={slideIndex}
                            src={slide}
                            alt="Slide"
                            className={`w-full h-full rounded-2xl object-cover duration-500 ${slideIndex === currentIndex ? 'opacity-100' : 'opacity-0'
                                } absolute`}
                        />
                    ))}
                    <div className='sm:hidden absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 hover:bg-white/50 text-white cursor-pointer transition duration-300'>
                        <BsChevronCompactLeft onClick={prevSlide} size={30} />
                    </div>
                    <div className='sm:hidden absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 hover:bg-white/50 transition duration-300 text-white cursor-pointer'>
                        <BsChevronCompactRight onClick={nextSlide} size={30} />
                    </div>
                    <div className='hidden sm:group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 hover:bg-white/50 text-white cursor-pointer transition duration-300'>
                        <BsChevronCompactLeft onClick={prevSlide} size={30} />
                    </div>
                    <div className='hidden sm:group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 hover:bg-white/50 transition duration-300 text-white cursor-pointer'>
                        <BsChevronCompactRight onClick={nextSlide} size={30} />
                    </div>
                </div>
                <div className='flex top-4 justify-center py-2'>
                    {slides.map((slide, slideIndex) => (
                        <div
                            key={slideIndex}
                            onClick={() => goToSlide(slideIndex)}
                            className='text-2xl cursor-pointer'
                        >
                            <RxDotFilled />
                        </div>
                    ))}
                </div>
            </div>
            <div className="h-[10vh] w-screen bg-background z-10 justify-center flex object-contain">
                <button
                    className="border-4 h-full rounded-xl bg-button w-full sm:w-1/2 text-3xl sm:text-5xl md:text-3xl lg:text-3xl text-white whitespace-normal px-6 py-2"
                    onClick={handleDownload}
                >
                    Download
                </button>
            </div>
        </div>
    )
}

export default ResumeAndHeadshots;
import React, { useState } from "react";
import insta from './content/icons8-instagram-50.png'
import youtube from './content/icons8-youtube-50.png'

function NavBar({ scrollToSection, homeRef, aboutRef, resumeRef, galleryRef, reelRef, contactRef }) {
    const [isOpen, setIsOpen] = useState(false)
    const genericHamburgerLine = `h-1 w-6 my-1 rounded-full bg-black transition ease transform duration-300`;

    const handleClick = (ref) => {
        if (isOpen) {
            setIsOpen(!isOpen)
            scrollToSection(ref)
        }
    }

    const openInsta = () => {
        const instagramPageUrl = 'https://www.instagram.com/isaprovidenceactor?igsh=bm9kZzJqandmbXBo';

        window.open(instagramPageUrl, '_blank');
    }

    const openYoutube = () => {
        const youtubeURL = 'https://www.youtube.com/@IsabellaM9016'

        window.open(youtubeURL, '_blank')
    }

    return (
        <>
            {window.innerWidth < 640 ? (
                <>
                    <div className="flex flex-row w-screen justify-center bg-nav items-center h-16 fixed z-50 top-0 border-b border-black">
                        <div className="relative right-[25vw] flex flex-row">
                            <img src={insta} className="scale-[80%] cursor-pointer" onClick={openInsta} />
                            <img src={youtube} className="cursor-pointer" onClick={openYoutube} />
                        </div>
                        <button className="flex flex-col h-10 w-12 fixed right-5 justify-center items-center group" onClick={() => setIsOpen(!isOpen)}>
                            <div className={`${genericHamburgerLine} ${isOpen ? "rotate-45 translate-y-3" : ""}`} />
                            <div className={`${genericHamburgerLine} ${isOpen ? "opacity-0" : ""}`} />
                            <div className={`${genericHamburgerLine} ${isOpen ? "-rotate-45 -translate-y-3" : ""}`} />
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <div className="flex flex-row w-screen justify-center bg-nav items-center h-16 fixed z-50 top-0 border-b border-black">
                        <div className="grid grid-cols-2 w-full">
                            <div className="flex gap-3 grid-col-1 justify-center">
                                <img src={insta} className="scale-[80%] cursor-pointer" onClick={openInsta} />
                                <img src={youtube} className="cursor-pointer" onClick={openYoutube} />
                            </div>
                            <div className="flex gap-5 grid-col-2 justify-center">
                                <button onClick={() => scrollToSection(homeRef)} className="text-xl">Home</button>
                                <button onClick={() => scrollToSection(aboutRef)} className="text-xl">About</button>
                                <button onClick={() => scrollToSection(resumeRef)} className="text-xl">Resume</button>
                                <button onClick={() => scrollToSection(galleryRef)} className="text-xl">Gallery</button>
                                <button onClick={() => scrollToSection(reelRef)} className="text-xl">Reels</button>
                                <button onClick={() => scrollToSection(contactRef)} className="text-xl">Contact</button>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {isOpen ?
                <div className="flex flex-col fixed left-0 w-1/2 h-screen z-50 gap-5 bg-nav border-black border-r transition-transform ease-in-out duration-500 transform-gpu -translate-x pt-5">
                    <button onClick={() => handleClick(homeRef)} className="text-2xl">Home</button>
                    <button onClick={() => handleClick(aboutRef)} className="text-2xl">About</button>
                    <button onClick={() => handleClick(resumeRef)} className="text-2xl">Resume</button>
                    <button onClick={() => handleClick(galleryRef)} className="text-2xl">Gallery</button>
                    <button onClick={() => handleClick(reelRef)} className="text-2xl">Reels</button>
                    <button onClick={() => handleClick(contactRef)} className="text-2xl">Contact</button>
                </div>
                :
                <div className="flex flex-col fixed left-0 w-1/2 h-screen z-50 gap-5 bg-nav border-black border-r transition-transform ease-in-out duration-500 transform-gpu -translate-x-full pt-5">
                    <button onClick={() => handleClick(homeRef)} className="text-2xl">Home</button>
                    <button onClick={() => handleClick(aboutRef)} className="text-2xl">About</button>
                    <button onClick={() => handleClick(resumeRef)} className="text-2xl">Resume</button>
                    <button onClick={() => handleClick(galleryRef)} className="text-2xl">Gallery</button>
                    <button onClick={() => handleClick(reelRef)} className="text-2xl">Reels</button>
                    <button onClick={() => handleClick(contactRef)} className="text-2xl">Contact</button>
                </div>
            }
        </>
    )
}

export default NavBar;